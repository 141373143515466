import { api } from '@/api';
import {
    IGreetingMessageCreate, IGreetingMessageUpdate, IMenuOptionCreate, IMenuOptionUpdate, IPerformanceCreate,
    IPerformanceUpdate, ISettingsUpdate, ITicketCreate, ITicketUpdate,
} from '@/interfaces';
import router from '@/router';
import { getLocalToken, removeLocalToken, saveLocalToken } from '@/utils';
import { AxiosError } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { State } from '../state';
import {
    commitAddNotification,
    commitRemoveNotification,
    commitSetLoggedIn,
    commitSetLogInError,
    commitSetToken,
    commitSetUserProfile,
    commitSetSites,
    commitSetDashboardStatistics,
    commitSetSiteCode,
    commitSetPerformances,
    commitSetPerformance,
    commitSetFilms,
    commitSetScreens,
    commitDeletePerformance,
    commitSetTickets,
    commitSetTicket,
    commitDeleteTicket,
    commitSetSounds,
    commitSetGreetingMessages,
    commitSetGreetingSounds,
    commitSetSite,
    commitSetMenuOptions,
    commitSetMenuSounds,
    commitSetLogsQuantity,
    commitSetCalls,
} from './mutations';
import { AppNotification, MainState } from './state';

type MainContext = ActionContext<MainState, State>;

export const actions = {
    async actionLogIn(context: MainContext, payload: { username: string; password: string }) {
        try {
            const response = await api.logInGetToken(payload.username, payload.password);
            const token = response.data.access_token;
            if (token) {
                saveLocalToken(token);
                commitSetToken(context, token);
                commitSetLoggedIn(context, true);
                commitSetLogInError(context, false);
                await dispatchGetUserProfile(context);
                await dispatchRouteLoggedIn(context);
                commitAddNotification(context, { content: 'Logged in', color: 'success' });
            } else {
                await dispatchLogOut(context);
            }
        } catch (err) {
            commitSetLogInError(context, true);
            await dispatchLogOut(context);
        }
    },
    async actionGetUserProfile(context: MainContext) {
        try {
            const response = await api.getMe(context.state.token);
            if (response.data) {
                commitSetUserProfile(context, response.data);
                if (response.data.site && response.data.site.code && !context.state.siteCode) {
                    commitSetSiteCode(context, response.data.site.code);
                }
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateUserProfile(context: MainContext, payload) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateMe(context.state.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUserProfile(context, response.data);
            if (response.data.site && response.data.site.code && !context.state.siteCode) {
                commitSetSiteCode(context, response.data.site.code);
            }
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Profile successfully updated', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCheckLoggedIn(context: MainContext) {
        if (!context.state.isLoggedIn) {
            let token = context.state.token;
            if (!token) {
                const localToken = getLocalToken();
                if (localToken) {
                    commitSetToken(context, localToken);
                    token = localToken;
                }
            }
            if (token) {
                try {
                    const response = await api.getMe(token);
                    commitSetLoggedIn(context, true);
                    commitSetUserProfile(context, response.data);
                    if (response.data.site && response.data.site.code && !context.state.siteCode) {
                        commitSetSiteCode(context, response.data.site.code);
                    }
                } catch (error) {
                    await dispatchRemoveLogIn(context);
                }
            } else {
                await dispatchRemoveLogIn(context);
            }
        }
    },
    async actionRemoveLogIn(context: MainContext) {
        removeLocalToken();
        commitSetToken(context, '');
        commitSetLoggedIn(context, false);
    },
    async actionLogOut(context: MainContext) {
        await dispatchRemoveLogIn(context);
        await dispatchRouteLogOut(context);
    },
    async actionUserLogOut(context: MainContext) {
        await dispatchLogOut(context);
        commitAddNotification(context, { content: 'Logged out', color: 'success' });
    },
    actionRouteLogOut(context: MainContext) {
        if (router.currentRoute.path !== '/login') {
            router.push('/login');
        }
    },
    async actionCheckApiError(context: MainContext, payload: AxiosError) {
        if (payload.response!.status === 401) {
            await dispatchLogOut(context);
        }
    },
    actionRouteLoggedIn(context: MainContext) {
        if (router.currentRoute.path === '/login' || router.currentRoute.path === '/') {
            router.push(context.state.siteCode ? `/${context.state.siteCode}/main` : '/main');
        }
    },
    async removeNotification(context: MainContext, payload: { notification: AppNotification, timeout: number }) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                commitRemoveNotification(context, payload.notification);
                resolve(true);
            }, payload.timeout);
        });
    },
    async passwordRecovery(context: MainContext, payload: { username: string }) {
        const loadingNotification = { content: 'Sending password recovery email', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.passwordRecovery(payload.username),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Password recovery email sent', color: 'success' });
            await dispatchLogOut(context);
        } catch (error) {
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { color: 'error', content: 'Incorrect username' });
        }
    },
    async resetPassword(context: MainContext, payload: { password: string, token: string }) {
        const loadingNotification = { content: 'Resetting password', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.resetPassword(payload.password, payload.token),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Password successfully reset', color: 'success' });
            await dispatchLogOut(context);
        } catch (error) {
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { color: 'error', content: 'Error resetting password' });
        }
    },
    async actionGetSites(context: MainContext) {
        try {
            const response = await api.getSites(context.rootState.main.token);
            if (response) {
                commitSetSites(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetDashboardStatistics(context: MainContext) {
        try {
            const response = await api.getDashboardStatistics(
                context.rootState.main.token, context.state.siteCode,
            );
            if (response) {
                commitSetDashboardStatistics(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetPerformances(context: MainContext, date: string) {
        try {
            const response = await api.getPerformances(
                context.rootState.main.token, context.state.siteCode, date,
            );
            if (response) {
                commitSetPerformances(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdatePerformance(
        context: MainContext, payload: { performanceCode: number, performance: IPerformanceUpdate },
    ) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updatePerformance(
                    context.rootState.main.token, payload.performanceCode, context.state.siteCode, payload.performance,
                ),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetPerformance(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Performance successfully updated', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreatePerformance(context: MainContext, payload: IPerformanceCreate) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createPerformance(context.rootState.main.token, context.state.siteCode, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetPerformance(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Performance successfully created', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionDeletePerformance(
        context: MainContext, performanceCode: number,
    ) {
        try {
            const loadingNotification = { content: 'deleting', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.deletePerformance(
                    context.rootState.main.token, performanceCode, context.state.siteCode,
                ),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitDeletePerformance(context, performanceCode);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Performance successfully deleted', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetFilms(context: MainContext) {
        try {
            const response = await api.getFilms(
                context.rootState.main.token, context.state.siteCode,
            );
            if (response) {
                commitSetFilms(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetScreens(context: MainContext) {
        try {
            const response = await api.getScreens(
                context.rootState.main.token, context.state.siteCode,
            );
            if (response) {
                commitSetScreens(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetTickets(context: MainContext) {
        try {
            const response = await api.getTickets(
                context.rootState.main.token, context.state.siteCode,
            );
            if (response) {
                commitSetTickets(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateTicket(
        context: MainContext, payload: {
            ticketCode: number, ticket: ITicketUpdate, soundFile: File, requestCustomRecording: string,
        },
    ) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateTicket(
                    context.rootState.main.token, payload.ticketCode, context.state.siteCode, payload.ticket,
                    payload.soundFile, payload.requestCustomRecording,
                ),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (payload.requestCustomRecording && response.data === null) {
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, {
                    content: 'Request successfully sent, we will let you know when available', color: 'success',
                });
            } else {
                commitSetTicket(context, response.data);
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: 'Ticket successfully updated', color: 'success' });
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateTicket(
        context: MainContext, payload: { ticket: ITicketCreate, soundFile: File, requestCustomRecording: string },
    ) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createTicket(
                    context.rootState.main.token, context.state.siteCode, payload.ticket,
                    payload.soundFile, payload.requestCustomRecording,
                ),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (payload.requestCustomRecording && response.data === null) {
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, {
                    content: 'Request successfully sent, we will let you know when available', color: 'success',
                });
            } else {
                commitSetTicket(context, response.data);
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: 'Ticket successfully created', color: 'success' });
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionDeleteTicket(context: MainContext, ticketCode: number) {
        try {
            const loadingNotification = { content: 'deleting', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.deleteTicket(
                    context.rootState.main.token, ticketCode, context.state.siteCode,
                ),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitDeleteTicket(context, ticketCode);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Ticket successfully deleted', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetSounds(context: MainContext) {
        try {
            const site = context.state.sites.find((s) => s.code === context.state.siteCode);
            const response = await api.getSounds(
                context.rootState.main.token, context.state.siteCode,
            );
            if (response) {
                commitSetSounds(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionMoveUpTicket(
        context: MainContext, payload: { ticketCode: number },
    ) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.moveUpTicket(
                    context.rootState.main.token, payload.ticketCode, context.state.siteCode,
                ),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetTicket(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Ticket successfully move up', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionMoveDownTicket(
        context: MainContext, payload: { ticketCode: number },
    ) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.moveDownTicket(
                    context.rootState.main.token, payload.ticketCode, context.state.siteCode,
                ),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetTicket(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Ticket successfully move down', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetGreetingMessages(context: MainContext) {
        try {
            const response = await api.getGreetingMessages(
                context.rootState.main.token, context.state.siteCode,
            );
            if (response) {
                commitSetGreetingMessages(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateGreetingMessage(
        context: MainContext, payload: { displayorder: number, greetingMessage: IGreetingMessageUpdate },
    ) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateGreetingMessage(
                    context.rootState.main.token, payload.displayorder, context.state.siteCode, payload.greetingMessage,
                ),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetGreetingMessages(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Greeting message successfully updated', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateGreetingMessage(context: MainContext, payload: IGreetingMessageCreate) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createGreetingMessage(context.rootState.main.token, context.state.siteCode, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetGreetingMessages(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Greeting message successfully created', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionDeleteGreetingMessage(context: MainContext, displayorder: number) {
        try {
            const loadingNotification = { content: 'deleting', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.deleteGreetingMessage(
                    context.rootState.main.token, displayorder, context.state.siteCode,
                ),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetGreetingMessages(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Greeting message successfully deleted', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionMoveUpGreetingMessage(
        context: MainContext, payload: { displayorder: number },
    ) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.moveUpGreetingMessage(
                    context.rootState.main.token, payload.displayorder, context.state.siteCode,
                ),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetGreetingMessages(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Greeting message successfully move up', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionMoveDownGreetingMessage(
        context: MainContext, payload: { displayorder: number },
    ) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.moveDownGreetingMessage(
                    context.rootState.main.token, payload.displayorder, context.state.siteCode,
                ),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetGreetingMessages(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Greeting message successfully move down', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetGreetingSounds(context: MainContext) {
        try {
            const response = await api.getGreetingSounds(
                context.rootState.main.token, context.state.siteCode,
            );
            if (response) {
                commitSetGreetingSounds(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateSettings(context: MainContext, settings: ISettingsUpdate) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateSettings(
                    context.rootState.main.token, context.state.siteCode, settings,
                ),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetSite(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Settings successfully updated', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetMenuOptions(context: MainContext) {
        try {
            const response = await api.getMenuOptions(
                context.rootState.main.token, context.state.siteCode,
            );
            if (response) {
                commitSetMenuOptions(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateMenuOption(
        context: MainContext, payload: { button: string, menuOption: IMenuOptionUpdate, soundFile: File },
    ) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateMenuOption(
                    context.rootState.main.token, payload.button, context.state.siteCode,
                    payload.menuOption, payload.soundFile,
                ),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetMenuOptions(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Menu option successfully updated', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateMenuOption(context: MainContext, payload: {menuOption: IMenuOptionCreate, soundFile: File}) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createMenuOption(
                    context.rootState.main.token, context.state.siteCode, payload.menuOption, payload.soundFile,
                ),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetMenuOptions(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Menu option successfully created', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionDeleteMenuOption(context: MainContext, button: string) {
        try {
            const loadingNotification = { content: 'deleting', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.deleteMenuOption(
                    context.rootState.main.token, button, context.state.siteCode,
                ),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetMenuOptions(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Menu option successfully deleted', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetMenuSounds(context: MainContext) {
        try {
            const response = await api.getMenuSounds(
                context.rootState.main.token, context.state.siteCode,
            );
            if (response) {
                commitSetMenuSounds(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetLogsQuantity(context: MainContext, dates: {start: string, end: string, timeUnit: string, siteOption: string}) {
        try {
            const response = await api.getLogsQuantity(
                context.rootState.main.token, context.state.siteCode, dates.start, dates.end, dates.timeUnit, dates.siteOption,
            );
            if (response) {
                commitSetLogsQuantity(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetCalls(context: MainContext, date: string) {
        try {
            const response = await api.getCalls(
                context.rootState.main.token, context.state.siteCode, date,
            );
            if (response) {
                commitSetCalls(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<MainState | any, State>('');

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile);
export const dispatchLogIn = dispatch(actions.actionLogIn);
export const dispatchLogOut = dispatch(actions.actionLogOut);
export const dispatchUserLogOut = dispatch(actions.actionUserLogOut);
export const dispatchRemoveLogIn = dispatch(actions.actionRemoveLogIn);
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn);
export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut);
export const dispatchUpdateUserProfile = dispatch(actions.actionUpdateUserProfile);
export const dispatchRemoveNotification = dispatch(actions.removeNotification);
export const dispatchPasswordRecovery = dispatch(actions.passwordRecovery);
export const dispatchResetPassword = dispatch(actions.resetPassword);
export const dispatchGetSites = dispatch(actions.actionGetSites);
export const dispatchGetDashboardStatistics = dispatch(actions.actionGetDashboardStatistics);
export const dispatchGetPerformances = dispatch(actions.actionGetPerformances);
export const dispatchCreatePerformance = dispatch(actions.actionCreatePerformance);
export const dispatchUpdatePerformance = dispatch(actions.actionUpdatePerformance);
export const dispatchDeletePerformance = dispatch(actions.actionDeletePerformance);
export const dispatchGetFilms = dispatch(actions.actionGetFilms);
export const dispatchGetScreens = dispatch(actions.actionGetScreens);
export const dispatchGetTickets = dispatch(actions.actionGetTickets);
export const dispatchCreateTicket = dispatch(actions.actionCreateTicket);
export const dispatchUpdateTicket = dispatch(actions.actionUpdateTicket);
export const dispatchDeleteTicket = dispatch(actions.actionDeleteTicket);
export const dispatchGetSounds = dispatch(actions.actionGetSounds);
export const dispatchMoveUpTicket = dispatch(actions.actionMoveUpTicket);
export const dispatchMoveDownTicket = dispatch(actions.actionMoveDownTicket);
export const dispatchGetGreetingMessages = dispatch(actions.actionGetGreetingMessages);
export const dispatchCreateGreetingMessage = dispatch(actions.actionCreateGreetingMessage);
export const dispatchUpdateGreetingMessage = dispatch(actions.actionUpdateGreetingMessage);
export const dispatchDeleteGreetingMessage = dispatch(actions.actionDeleteGreetingMessage);
export const dispatchMoveUpGreetingMessage = dispatch(actions.actionMoveUpGreetingMessage);
export const dispatchMoveDownGreetingMessage = dispatch(actions.actionMoveDownGreetingMessage);
export const dispatchGetGreetingSounds = dispatch(actions.actionGetGreetingSounds);
export const dispatchUpdateSettings = dispatch(actions.actionUpdateSettings);
export const dispatchGetMenuOptions = dispatch(actions.actionGetMenuOptions);
export const dispatchCreateMenuOption = dispatch(actions.actionCreateMenuOption);
export const dispatchUpdateMenuOption = dispatch(actions.actionUpdateMenuOption);
export const dispatchDeleteMenuOption = dispatch(actions.actionDeleteMenuOption);
export const dispatchGetMenuSounds = dispatch(actions.actionGetMenuSounds);
export const dispatchGetLogsQuantity = dispatch(actions.actionGetLogsQuantity);
export const dispatchGetCalls = dispatch(actions.actionGetCalls);
