import '@babel/polyfill';
// Import Component hooks before component definitions
import './component-hooks';
import Vue from 'vue';
import './plugins/vee-validate';
import App from './App.vue';
import router from './router';
import store from '@/store';
import './registerServiceWorker';
import 'vuetify/dist/vuetify.min.css';
import vuetify from './plugins/vuetify';
// import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { environment } from '@/env';

Sentry.init({
  Vue,
  dsn: 'https://9cef09f063f94849984336193352f290@sentry.jacro.com/23',
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: environment !== 'production' ? 1.0 : 0.2,
  logErrors: true,
  environment,
});

// Vue.use(VueTelInputVuetify, {
//   vuetify,
// });

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
