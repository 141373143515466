import { MainState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    hasAdminAccess: (state: MainState) => {
        return (
            state.userProfile &&
            state.userProfile.is_superuser && state.userProfile.is_active);
    },
    loginError: (state: MainState) => state.logInError,
    dashboardShowDrawer: (state: MainState) => state.dashboardShowDrawer,
    dashboardMiniDrawer: (state: MainState) => state.dashboardMiniDrawer,
    userProfile: (state: MainState) => state.userProfile,
    token: (state: MainState) => state.token,
    isLoggedIn: (state: MainState) => state.isLoggedIn,
    firstNotification: (state: MainState) => state.notifications.length > 0 && state.notifications[0],
    sites: (state: MainState) => state.sites,
    dashboardStatistics: (state: MainState) => state.dashboardStatistics,
    siteCode: (state: MainState) => state.siteCode,
    prefixSiteUrl: (state: MainState) => {
        return state.siteCode ? `/${state.siteCode}` : '';
    },
    performances: (state: MainState) => state.performances,
    films: (state: MainState) => state.films,
    screens: (state: MainState) => state.screens,
    tickets: (state: MainState) => state.tickets,
    sounds: (state: MainState) => state.sounds,
    greetingMessages: (state: MainState) => state.greetingMessages,
    greetingSounds: (state: MainState) => state.greetingSounds,
    menuOptions: (state: MainState) => state.menuOptions,
    menuSounds: (state: MainState) => state.menuSounds,
    logsQuantity: (state: MainState) => state.logsQuantity,
    calls: (state: MainState) => state.calls,
};

const {read} = getStoreAccessors<MainState, State>('');

export const readDashboardMiniDrawer = read(getters.dashboardMiniDrawer);
export const readDashboardShowDrawer = read(getters.dashboardShowDrawer);
export const readHasAdminAccess = read(getters.hasAdminAccess);
export const readIsLoggedIn = read(getters.isLoggedIn);
export const readLoginError = read(getters.loginError);
export const readToken = read(getters.token);
export const readUserProfile = read(getters.userProfile);
export const readFirstNotification = read(getters.firstNotification);
export const readSites = read(getters.sites);
export const readDashboardStatistics = read(getters.dashboardStatistics);
export const readSiteCode = read(getters.siteCode);
export const readPrefixSiteUrl = read(getters.prefixSiteUrl);
export const readPerformances = read(getters.performances);
export const readFilms = read(getters.films);
export const readScreens = read(getters.screens);
export const readTickets = read(getters.tickets);
export const readSounds = read(getters.sounds);
export const readGreetingMessages = read(getters.greetingMessages);
export const readGreetingSounds = read(getters.greetingSounds);
export const readMenuOptions = read(getters.menuOptions);
export const readMenuSounds = read(getters.menuSounds);
export const readLogsQuantity = read(getters.logsQuantity);
export const readCalls = read(getters.calls);
