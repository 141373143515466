import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { MainState } from './state';

const defaultState: MainState = {
  isLoggedIn: null,
  token: '',
  logInError: false,
  userProfile: null,
  dashboardMiniDrawer: false,
  dashboardShowDrawer: true,
  notifications: [],
  sites: [],
  dashboardStatistics: null,
  siteCode: '',
  performances: [],
  // performancesDate: new Date(),
  films: [],
  screens: [],
  tickets: [],
  sounds: null,
  greetingMessages: [],
  greetingSounds: null,
  menuOptions: [],
  menuSounds: null,
  logsQuantity: {
    smses: [],
    calls: [],
  },
  calls: [],
};

export const mainModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
