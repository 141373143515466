import {
    IUserProfile, ISite, IDashboardStatistics, IPerformance, IFilm, IScreen,
    ITicket, ISounds, IGreetingMessage, IGreetingSounds, IMenuOption, IMenuSounds, ILogsQuantity, ICallLog,
} from '@/interfaces';
import { MainState, AppNotification } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';


export const mutations = {
    setToken(state: MainState, payload: string) {
        state.token = payload;
    },
    setLoggedIn(state: MainState, payload: boolean) {
        state.isLoggedIn = payload;
    },
    setLogInError(state: MainState, payload: boolean) {
        state.logInError = payload;
    },
    setUserProfile(state: MainState, payload: IUserProfile) {
        state.userProfile = payload;
    },
    setDashboardMiniDrawer(state: MainState, payload: boolean) {
        state.dashboardMiniDrawer = payload;
    },
    setDashboardShowDrawer(state: MainState, payload: boolean) {
        state.dashboardShowDrawer = payload;
    },
    addNotification(state: MainState, payload: AppNotification) {
        state.notifications.push(payload);
    },
    removeNotification(state: MainState, payload: AppNotification) {
        state.notifications = state.notifications.filter((notification) => notification !== payload);
    },
    setSites(state: MainState, payload: ISite[]) {
        state.sites = payload;
    },
    setDashboardStatistics(state: MainState, payload: IDashboardStatistics) {
        state.dashboardStatistics = payload;
    },
    setSiteCode(state: MainState, payload: string) {
        state.siteCode = payload;
    },
    setPerformances(state: MainState, payload: IPerformance[]) {
        state.performances = payload;
    },
    setPerformance(state: MainState, payload: IPerformance) {
        let isCreating: boolean = true;
        const performances = state.performances.map(
            (performance: IPerformance) => {
                if (performance.code === payload.code) {
                    isCreating = false;
                    return payload;
                } else {
                    return performance;
                }
            },
        );
        if (isCreating) {
            performances.push(payload);
        }
        state.performances = performances;
    },
    setFilms(state: MainState, payload: IFilm[]) {
        state.films = payload;
    },
    setScreens(state: MainState, payload: IScreen[]) {
        state.screens = payload;
    },
    deletePerformance(state: MainState, performanceCode: number) {
        const performances = state.performances.filter(
            (performance: IPerformance) => performance.code !== performanceCode,
        );
        state.performances = performances;
    },
    setTickets(state: MainState, payload: ITicket[]) {
        state.tickets = payload;
    },
    setTicket(state: MainState, payload: ITicket) {
        let isCreating: boolean = true;
        const tickets = state.tickets.map(
            (ticket: ITicket) => {
                if (ticket.code === payload.code) {
                    isCreating = false;
                    return payload;
                } else {
                    return ticket;
                }
            },
        );
        if (isCreating) {
            tickets.push(payload);
        }
        state.tickets = tickets;
    },
    deleteTicket(state: MainState, ticketCode: number) {
        const tickets = state.tickets.filter(
            (ticket: ITicket) => ticket.code !== ticketCode,
        );
        state.tickets = tickets;
    },
    setSounds(state: MainState, payload: ISounds) {
        state.sounds = payload;
    },
    setGreetingMessages(state: MainState, payload: IGreetingMessage[]) {
        state.greetingMessages = payload;
    },
    setGreetingSounds(state: MainState, payload: IGreetingSounds) {
        state.greetingSounds = payload;
    },
    setSite(state: MainState, payload: ISite) {
        const sites = state.sites.map(
            (site: ISite) => {
                if (site.code === payload.code) {
                    return payload;
                } else {
                    return site;
                }
            },
        );
        state.sites = sites;
    },
    setMenuOptions(state: MainState, payload: IMenuOption[]) {
        state.menuOptions = payload;
    },
    setMenuSounds(state: MainState, payload: IMenuSounds) {
        state.menuSounds = payload;
    },
    setLogsQuantity(state: MainState, payload: ILogsQuantity) {
        state.logsQuantity = payload;
    },
    setCalls(state: MainState, payload: ICallLog[]) {
        state.calls = payload;
    },
};

const {commit} = getStoreAccessors<MainState | any, State>('');

export const commitSetDashboardMiniDrawer = commit(mutations.setDashboardMiniDrawer);
export const commitSetDashboardShowDrawer = commit(mutations.setDashboardShowDrawer);
export const commitSetLoggedIn = commit(mutations.setLoggedIn);
export const commitSetLogInError = commit(mutations.setLogInError);
export const commitSetToken = commit(mutations.setToken);
export const commitSetUserProfile = commit(mutations.setUserProfile);
export const commitAddNotification = commit(mutations.addNotification);
export const commitRemoveNotification = commit(mutations.removeNotification);
export const commitSetSites = commit(mutations.setSites);
export const commitSetDashboardStatistics = commit(mutations.setDashboardStatistics);
export const commitSetSiteCode = commit(mutations.setSiteCode);
export const commitSetPerformances = commit(mutations.setPerformances);
export const commitSetPerformance = commit(mutations.setPerformance);
export const commitSetFilms = commit(mutations.setFilms);
export const commitSetScreens = commit(mutations.setScreens);
export const commitDeletePerformance = commit(mutations.deletePerformance);
export const commitSetTickets = commit(mutations.setTickets);
export const commitSetTicket = commit(mutations.setTicket);
export const commitDeleteTicket = commit(mutations.deleteTicket);
export const commitSetSounds = commit(mutations.setSounds);
export const commitSetGreetingMessages = commit(mutations.setGreetingMessages);
export const commitSetGreetingSounds = commit(mutations.setGreetingSounds);
export const commitSetSite = commit(mutations.setSite);
export const commitSetMenuOptions = commit(mutations.setMenuOptions);
export const commitSetMenuSounds = commit(mutations.setMenuSounds);
export const commitSetLogsQuantity = commit(mutations.setLogsQuantity);
export const commitSetCalls = commit(mutations.setCalls);
