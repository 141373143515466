import Vue from 'vue';
import Vuetify from 'vuetify';
import '@mdi/font/css/materialdesignicons.css';
import VCurrencyField from 'v-currency-field';
import AudioRecorder from 'vue-audio-recorder-x';
import axios from 'axios';
import { Player, Audio } from '@vime/vue';
// import colors from 'vuetify/lib/util/colors';
import { Bar } from 'vue-chartjs/legacy';
import DatetimePicker from 'vuetify-datetime-picker';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, TimeScale, LinearScale } from 'chart.js';
import 'chartjs-adapter-moment';

ChartJS.register(Title, Tooltip, Legend, BarElement, TimeScale, LinearScale);

Vue.prototype.$http = axios;

Vue.use(Vuetify);
Vue.use(VCurrencyField);
Vue.use(AudioRecorder);
Vue.use(DatetimePicker);

Vue.component('Player', Player);
Vue.component('Audio', Audio);
Vue.component('Bar', Bar);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#B11F34', // colors.red.darken1, // #E53935
        // secondary: colors.red.lighten4, // #FFCDD2
        // accent: colors.red.accent1, // #3F51B5
      },
    },
  },
});
