import axios, { AxiosRequestTransformer } from 'axios';
import { apiUrl } from '@/env';
import {
  IUserProfile, IUserProfileUpdate, IUserProfileCreate, ISite, ICustomer, IDashboardStatistics,
  IPerformance, IPerformanceCreate, IPerformanceUpdate, IFilm, IScreen, ITicket, ITicketUpdate,
  ITicketCreate, ISounds, IGreetingMessage, IGreetingMessageCreate, IGreetingMessageUpdate,
  IGreetingSounds, ISettingsUpdate, IMenuOption, IMenuOptionCreate, IMenuOptionUpdate, IMenuSounds, ILogsQuantity, ICallLog,
} from './interfaces';
import moment from 'moment';

const dateTransformer = (data: any): any => {
  if (data instanceof Date) {
    return moment(data).format('YYYY-MM-DD');
  }
  if (Array.isArray(data)) {
    return data.map(dateTransformer);
  }
  if (typeof data === 'object' && data !== null) {
    return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, dateTransformer(value)]));
  }
  return data;
};

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    transformRequest: [dateTransformer, ...(axios.defaults.transformRequest as AxiosRequestTransformer[])],
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  async getSites(token: string) {
    return axios.get<ISite[]>(`${apiUrl}/api/v1/sites/`, authHeaders(token));
  },
  async getCustomers(token: string) {
    return axios.get<ICustomer[]>(`${apiUrl}/api/v1/customers/`, authHeaders(token));
  },
  async getDashboardStatistics(token: string, site: string) {
    return axios.get<IDashboardStatistics>(`${apiUrl}/api/v1/dashboard/site/${site}/statistics`, authHeaders(token));
  },
  async getPerformances(token: string, site: string, perfdate: string) {
    return axios.get<IPerformance[]>(
      `${apiUrl}/api/v1/performances/site/${site}/perfdate/${perfdate}`,
      authHeaders(token),
    );
  },
  async updatePerformance(token: string, performanceCode: number, site: string, data: IPerformanceUpdate) {
    return axios.put(`${apiUrl}/api/v1/performances/${performanceCode}/site/${site}`, data, authHeaders(token));
  },
  async createPerformance(token: string, site: string, data: IPerformanceCreate) {
    return axios.post(`${apiUrl}/api/v1/performances/site/${site}`, data, authHeaders(token));
  },
  async getFilms(token: string, site: string) {
    return axios.get<IFilm[]>(
      `${apiUrl}/api/v1/films/site/${site}`, authHeaders(token),
    );
  },
  async getScreens(token: string, site: string) {
    return axios.get<IScreen[]>(
      `${apiUrl}/api/v1/screens/site/${site}`, authHeaders(token),
    );
  },
  async deletePerformance(token: string, performanceCode: number, site: string) {
    return axios.delete(`${apiUrl}/api/v1/performances/${performanceCode}/site/${site}`, authHeaders(token));
  },
  async getTickets(token: string, site: string) {
    return axios.get<ITicket[]>(
      `${apiUrl}/api/v1/tickets/site/${site}`,
      authHeaders(token),
    );
  },
  async updateTicket(
    token: string, ticketCode: number, site: string,
    ticket: ITicketUpdate, soundFile: File, requestCustomRecording: string,
  ) {
    const fd = new FormData();
    fd.append('ticket_in', JSON.stringify(ticket));
    if (soundFile) {
      fd.append('sound_file', soundFile);
    }
    fd.append('request_custom_recording', requestCustomRecording);
    const config = authHeaders(token);
    config.transformRequest = axios.defaults.transformRequest as AxiosRequestTransformer[];
    config.headers['Content-Type'] = 'multipart/form-data';
    return axios.put(`${apiUrl}/api/v1/tickets/${ticketCode}/site/${site}`, fd, config);
  },
  async createTicket(
    token: string, site: string, ticket: ITicketCreate, soundFile: File, requestCustomRecording: string,
  ) {
    const fd = new FormData();
    fd.append('ticket_in', JSON.stringify(ticket));
    if (soundFile) {
      fd.append('sound_file', soundFile);
    }
    fd.append('request_custom_recording', requestCustomRecording);
    const config = authHeaders(token);
    config.transformRequest = axios.defaults.transformRequest as AxiosRequestTransformer[];
    config.headers['Content-Type'] = 'multipart/form-data';
    return axios.post(`${apiUrl}/api/v1/tickets/site/${site}`, fd, config);
  },
  async deleteTicket(token: string, ticketCode: number, site: string) {
    return axios.delete(`${apiUrl}/api/v1/tickets/${ticketCode}/site/${site}`, authHeaders(token));
  },
  async getSounds(token: string, site: string) {
    return axios.get<ISounds>(
      `${apiUrl}/api/v1/sounds/site/${site}`, authHeaders(token),
    );
  },
  async moveUpTicket(token: string, ticketCode: number, site: string) {
    return axios.put(`${apiUrl}/api/v1/tickets/move-up/${ticketCode}/site/${site}`, {}, authHeaders(token));
  },
  async moveDownTicket(token: string, ticketCode: number, site: string) {
    return axios.put(`${apiUrl}/api/v1/tickets/move-down/${ticketCode}/site/${site}`, {}, authHeaders(token));
  },
  async getGreetingMessages(token: string, site: string) {
    return axios.get<IGreetingMessage[]>(
      `${apiUrl}/api/v1/greeting-messages/site/${site}`,
      authHeaders(token),
    );
  },
  async createGreetingMessage(token: string, site: string, data: IGreetingMessageCreate) {
    return axios.post<IGreetingMessage[]>(`${apiUrl}/api/v1/greeting-messages/site/${site}`, data, authHeaders(token));
  },
  async updateGreetingMessage(token: string, displayorder: number, site: string, data: IGreetingMessageUpdate) {
    return axios.put<IGreetingMessage[]>(
      `${apiUrl}/api/v1/greeting-messages/${displayorder}/site/${site}`, data, authHeaders(token),
    );
  },
  async deleteGreetingMessage(token: string, displayorder: number, site: string) {
    return axios.delete<IGreetingMessage[]>(
      `${apiUrl}/api/v1/greeting-messages/${displayorder}/site/${site}`, authHeaders(token),
    );
  },
  async getGreetingSounds(token: string, site: string) {
    return axios.get<IGreetingSounds>(
      `${apiUrl}/api/v1/greeting-sounds/site/${site}`, authHeaders(token),
    );
  },
  async moveUpGreetingMessage(token: string, displayorder: number, site: string) {
    return axios.put<IGreetingMessage[]>(
      `${apiUrl}/api/v1/greeting-messages/move-up/${displayorder}/site/${site}`, {}, authHeaders(token),
    );
  },
  async moveDownGreetingMessage(token: string, displayorder: number, site: string) {
    return axios.put<IGreetingMessage[]>(
      `${apiUrl}/api/v1/greeting-messages/move-down/${displayorder}/site/${site}`, {}, authHeaders(token),
    );
  },
  async updateSettings(token: string, site: string, data: ISettingsUpdate) {
    return axios.put<ISite>(`${apiUrl}/api/v1/settings/site/${site}`, data, authHeaders(token));
  },
  async getMenuOptions(token: string, site: string) {
    return axios.get<IMenuOption[]>(
      `${apiUrl}/api/v1/menu-options/site/${site}`,
      authHeaders(token),
    );
  },
  async createMenuOption(token: string, site: string, data: IMenuOptionCreate, soundFile: File) {
    const fd = new FormData();
    fd.append('option_in', JSON.stringify(data));
    if (soundFile) {
      fd.append('sound_file', soundFile);
    }
    const config = authHeaders(token);
    config.transformRequest = axios.defaults.transformRequest as AxiosRequestTransformer[];
    config.headers['Content-Type'] = 'multipart/form-data';
    return axios.post<IMenuOption[]>(`${apiUrl}/api/v1/menu-options/site/${site}`, fd, config);
  },
  async updateMenuOption(token: string, button: string, site: string, data: IMenuOptionUpdate, soundFile: File) {
    const fd = new FormData();
    fd.append('option_in', JSON.stringify(data));
    if (soundFile) {
      fd.append('sound_file', soundFile);
    }
    const config = authHeaders(token);
    config.transformRequest = axios.defaults.transformRequest as AxiosRequestTransformer[];
    config.headers['Content-Type'] = 'multipart/form-data';
    return axios.put<IMenuOption[]>(`${apiUrl}/api/v1/menu-options/${button}/site/${site}`, fd, config);
  },
  async deleteMenuOption(token: string, button: string, site: string) {
    return axios.delete<IMenuOption[]>(
      `${apiUrl}/api/v1/menu-options/${button}/site/${site}`, authHeaders(token),
    );
  },
  async getMenuSounds(token: string, site: string) {
    return axios.get<IMenuSounds>(
      `${apiUrl}/api/v1/menu-sounds/site/${site}`, authHeaders(token),
    );
  },
  async getLogsQuantity(token: string, site: string, start: string, end: string, timeUnit: string, siteOption: string) {
    return axios.get<ILogsQuantity>(
      `${apiUrl}/api/v1/reports/logs-quantity/site-option/${siteOption}/site/${site}/time-unit/${timeUnit}/start/${start}/end/${end}`,
      authHeaders(token),
    );
  },
  async getCalls(token: string, site: string, date: string) {
    return axios.get<ICallLog[]>(
      `${apiUrl}/api/v1/calls/site/${site}/date/${date}`, authHeaders(token),
    );
  },
};
